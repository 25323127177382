<template>
  <div class="detail-page">
    <div class="detail-header">
      <img @click="back" src="@/assets/img/zhang/back.png" alt="" />
      <span>更多话题</span>
    </div>
    <div class="page">
      <Breadcrumb :routerList="routerList"></Breadcrumb>
      <!-- 筛选框 -->
      <el-card shadow="never" class="card1 card m-t-16">
        <el-input style="width: 15rem" v-model="secrchValue" prefix-icon="el-icon-search"
          placeholder="模糊搜索话题名称/关键字"></el-input>
        <span class="timefont" style="margin-left: 2rem">更新时间</span>
        <el-date-picker style="height: 2.25rem" class="m-l-16" v-model="timeArr" type="date"
          placeholder="请选择更新时间"></el-date-picker>
        <div class="btn2 m-l" @click="remakeList">重置</div>
        <div class="btn" @click="secrchCloseSpecialActionsList">查询</div>
      </el-card>
      <!-- 表格 -->
      <el-card shadow="never" class="m-t card">
        <!-- 表格 -->
        <el-table v-loading="specialActionsLoading" class="m-t" stripe :data="tableData" :header-cell-style="{
          'text-align': 'center',
          background: '#F6F7FA',
          color: '#333',
        }" :cell-style="{ 'text-align': 'center' }" style="
            width: 100%;
            text-align: center;
            border: 0.0625rem solid #e4e6ec;
            border-bottom: none;
          ">
          <el-table-column label="序号" type="index" width="100"></el-table-column>
          <el-table-column label="话题名称" width="410">
            <template slot-scope="scope">
              <span style="color: #f58030; cursor: pointer" @click="goDetail(scope.row)">
                {{ scope.row.name }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="startTime" label="开始时间"></el-table-column>
          <el-table-column prop="endTime" label="结束时间"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div class="cz">
                <div @click="startSpecialActions(scope.row)">开始更新</div>
                <div @click="openError(scope.row)">删除</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <Pagination style="margin: 0rem; margin-top: 0.9375rem" :pageNumber="pageData.page" :pageSize="pageData.limit"
          :total="total" @SizeChange="SizeChange" @CurrentChange="CurrentChange" />
      </el-card>
    </div>
    <!-- 删除二次确认框 -->
    <deleteDialog ref="openDialog" @deleteFn="deleteSpecialActions"></deleteDialog>
  </div>
</template>

<script>
import Pagination from "@/components/pagination";
import Breadcrumb from "@/components/breadcrumb.vue";
import Special from "@/api/special/index";
import deleteDialog from "@/components/deleteDialog.vue";
export default {
  components: {
    Breadcrumb,
    Pagination,
    deleteDialog,
  },
  data() {
    return {
      specialActionsLoading: false, //加载效果
      routerList: ["专项行动", "更多话题"], //面包屑
      secrchValue: "", // 报告名称
      timeArr: null, // 时间
      //表格数据
      tableData: [],
      //分页
      pageData: {
        page: 1,
        limit: 10,
      },
      total: 0,
    };
  },
  methods: {
    // 开始更新
    async startSpecialActions(item) {
      const res = await Special.startSpecialActions(item.id);
      if (res.data.code == 200) {
        this.$message.success("更新成功！");
        this.getCloseSpecialActionsList();
      }
    },
    // 重置
    remakeList() {
      this.timeArr = null;
      this.secrchValue = "";
      this.pageData = {
        page: 1,
        limit: 10,
      };
      this.total = 10;
      this.getCloseSpecialActionsList();
    },
    // 查询
    secrchCloseSpecialActionsList() {
      this.pageData = {
        page: 1,
        limit: 10,
      };
      this.total = 10;
      this.getCloseSpecialActionsList();
    },
    // 删除
    async deleteSpecialActions(item) {
      const res = await Special.deleteSpecialActions(item.item.id);
      if (res.data.code == 200) {
        this.$message.success("删除成功！");
        const currentPageDataCount = this.total - (this.pageData.page - 1) * this.pageData.limit;
        if (currentPageDataCount <= 1 && this.pageData.page > 1) {
          // 如果当前页是最后一页且数据条数小于等于1，则跳转到上一页
          this.pageData.page -= 1;
        }
        this.getCloseSpecialActionsList();
      }
    },
    // 打开删除弹窗
    openError(item) {
      item.message = "此条专项行动数据";
      this.$refs.openDialog.openDialog(item);
    },
    // 获取列表数据
    async getCloseSpecialActionsList() {
      this.specialActionsLoading = true;
      let data = {
        limit: this.pageData.limit,
        page: this.pageData.page,
      };
      if (this.timeArr) {
        data.updateTime = this.$parent.formatDate(this.timeArr);
      }
      if (this.secrchValue != "") {
        data.name = this.secrchValue;
      }
      const res = await Special.getCloseSpecialActionsList(data);
      if (res.data.code == 200) {
        this.total = res.data.data.totalCount / 1;
        this.tableData = res.data.data.list;
        this.specialActionsLoading = false;
      } else {
        this.tableData = [];
        this.specialActionsLoading = false;
      }
    },
    back() {
      this.$router.go(-1);
    },
    // 进入详情页
    goDetail(item) {
      let routeData = this.$router.resolve({
        path: "/topicListDetails",
        query: {
          info: encodeURIComponent(
            JSON.stringify({
              id: item.id,
              title: item.name,
              item: item,
            })
          ),
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 分页
    SizeChange(val) {
      this.pageData.limit = val;
      this.pageData.page = 1;
      this.getCloseSpecialActionsList();
    },
    CurrentChange(val) {
      this.pageData.page = val;
      this.getCloseSpecialActionsList();
    },
  },
  created() {
    this.getCloseSpecialActionsList();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-loading-spinner .path {
  stroke: #ed731f;
}

::v-deep .el-range-separator {
  width: 24px;
}

.detail-page {
  width: 100%;
  display: flex;
  flex-direction: column;

  .detail-header {
    width: 100%;
    height: 56px;
    background-color: #ffffff;
    display: flex;
    justify-content: start;

    img {
      width: 38px;
      height: 38px;
      margin: 9px 15px 9px 23px;
      cursor: pointer;
    }

    span {
      font-size: 20px;
      font-weight: 600;
      line-height: 56px;
      font-family: PingFangSC-Semibold, PingFang SC;
    }
  }

  .page {
    padding-top: 16px;

    .card1 {
      ::v-deep .el-input__inner {
        height: 36px;
      }
    }

    .card {
      .timefont {
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
      }

      .btn {
        width: auto;
        padding: 8px 14px 8px 14px;
        background: #f58030;
        color: #ffffff;
        border-radius: 4px;
        float: right;
        cursor: pointer;
      }

      .btn2 {
        width: auto;
        padding: 8px 14px 8px 14px;
        background: #ffffff;
        color: #333333;
        border-radius: 4px;
        border: 1px solid #e1e1e1;
        float: right;
        cursor: pointer;
      }

      .m-l {
        margin-left: 16px;
      }

      .sum {
        color: #f58030;
      }

      .cz {
        display: flex;
        justify-content: center;

        div {
          padding-right: 16px;
          padding-left: 16px;
          border-right: 1px solid #e4e6ec;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          cursor: pointer;
        }

        div:nth-of-type(1) {
          color: #02bc7c;
        }

        div:nth-of-type(2) {
          color: #2e59ec;
        }

        div:last-child {
          color: #ea3342;
          border: none;
        }
      }

      .xzbg {
        width: 100%;
        height: 36px;

        .xzbgbtn {
          width: auto;
          padding: 8px 14px 8px 14px;
          background: #f58030;
          color: #ffffff;
          border-radius: 4px;
          float: right;
          cursor: pointer;
        }
      }

      //表格内容颜色
      ::v-deep .el-table__body tbody tr:nth-child(odd) {
        background-color: #ffffff;
      }

      ::v-deep .el-table__body tbody tr:nth-child(even) td {
        background-color: #f6f7fa;
      }
    }
  }
}
</style>
